import { graphql } from 'gatsby'
import * as React from 'react'
import { ButtonFragment, HeroFragment } from '../../schemas/graphqlTypings'
import { Button } from './button'

export type HeroProps = HeroFragment & {
  isMobile: boolean
}

const heroStyle = (backgroundImageUrl: string): React.CSSProperties => ({
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundPosition: 'center',
  minHeight: '70vh',
  backgroundSize: 'cover',
  padding: '4em',
})

const titleStyle: React.CSSProperties = {
  color: 'var(--colour-white)',
  fontSize: '4em',
}

const titleStyleMobile = (title: string): React.CSSProperties => {
  const words = title.split(' ')
  let longest = words[0].length
  if (words.length > 1) {
    for (let word of words) {
      longest = word.length > longest ? word.length : longest
    }
  }
  return {
    color: 'var(--colour-white)',
    fontSize: longest < 9 ? '3em' : '2.2em',
  }
}

export default function Hero(props: HeroProps) {
  return (
    <div className="hero" style={heroStyle(props?.backgroundImage?.url ?? '')}>
      <div>
        {props.title && (
          <p
            style={props.isMobile ? titleStyleMobile(props.title) : titleStyle}
          >
            {props.title}
          </p>
        )}
        {props.bookingButtons?.map((button, index) => {
          return <Button key={index} isMobile={props.isMobile} {...(button as ButtonFragment)} />
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment Hero on DatoCmsHero {
    id
    internal {
      type
    }
    backgroundImage {
      url
    }
    title
    bookingButtons {
      ... on DatoCmsButton {
        ...Button
      }
    }
  }
`
