import { graphql } from 'gatsby'
import * as React from 'react'
import { TextBlockFragment } from '../../schemas/graphqlTypings'
import StructuredTextBlock, { StructuredTextProps } from './structuredTextBlock'
import '../styles/text.scss'

export type TextBlockProps = TextBlockFragment & {
  isMobile: boolean
}

const containerStyle = (
  hex: string | null | undefined,
  isMobile: boolean
): React.CSSProperties => ({
  backgroundColor: hex ?? 'inherit',
  display: 'flex',
  flexDirection: isMobile ? 'column-reverse' : 'row',
  minHeight: '300px',
})

const imageStyle = (isMobile: boolean): React.CSSProperties => ({
  width: isMobile ? '100%' : '50%',
  objectFit: 'cover',
})

const commonTextStyle: React.CSSProperties = {
  minHeight: '300%',
}

const textStyle = (isMobile: boolean): React.CSSProperties => ({
  ...commonTextStyle,
  width: 'auto',
  padding: isMobile ? '2em' : '3em 5em',
  textAlign: isMobile ? 'center' : 'left',
})

const splitTextStyle: React.CSSProperties = {
  ...commonTextStyle,
  width: '50%',
  padding: '3em',
}

const TextBlock = (props: TextBlockProps) => {
  return (
    <div>
      <div
        style={containerStyle(props.backgroundColor?.hex, props.isMobile)}
        className="text-block"
      >
        <div
          style={
            props.image && !props.isMobile
              ? splitTextStyle
              : textStyle(props.isMobile)
          }
        >
          <StructuredTextBlock
            {...({
              data: props.text,
              renderWave: false,
            } as StructuredTextProps)}
          />
        </div>
        {props.image && (
          <img
            style={imageStyle(props.isMobile)}
            src={props.image.url ?? ''}
            alt={props.image.alt ?? ''}
          />
        )}
      </div>
    </div>
  )
}

export default TextBlock

export const query = graphql`
  fragment TextBlock on DatoCmsTextBlock {
    internal {
      type
    }
    image {
      alt
      url
    }
    text {
      links
      value
      blocks
    }
    backgroundColor {
      hex
    }
  }
`
