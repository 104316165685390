import { graphql } from 'gatsby'
import * as React from 'react'
import { LocationFragment } from '../../schemas/graphqlTypings'

export type LocationProps = LocationFragment & {
  isMobile: boolean
}

type CountryProps = {
  cities?: [
    {
      cityName: string
    }
  ]
  countryName: string
  isMobile: boolean
}

const countriesContainerStyleMobile: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const countriesContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
}

const locationStyleCommon: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '32px',
  padding: '32px 0',
}

const locationStyleMobile: React.CSSProperties = {
  ...locationStyleCommon,
  alignItems: 'center',
}

const locationStyle: React.CSSProperties = {
  ...locationStyleCommon,
  alignItems: 'flex-start',
}

const cityStyleMobile: React.CSSProperties = {
  border: '2px solid var(--colour-blue)',
  borderRadius: '10px',
  margin: '4px 0 0 0',
  padding: '12px 0 12px 0px',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '14px',
  minWidth: '280px',
}

const cityStyle: React.CSSProperties = {
  border: '2px solid var(--colour-blue)',
  width: '180px',
  borderRadius: '10px',
  margin: '4px 8px 0 0',
  padding: '10px 32px 10px 8px',
  fontWeight: 'bold',
  fontSize: '16px',
}

const countryStyleCommon: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
}

const countryStyle: React.CSSProperties = {
  ...countryStyleCommon,
  width: '40%',
}

const countryStyleMobile: React.CSSProperties = {
  ...countryStyleCommon,
  alignItems: 'center',
  width: '100%',
}

const cityListStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
}

const cityListStyleMobile: React.CSSProperties = {
  ...cityListStyle,
  justifyContent: 'space-evenly',
  minWidth: '-webkit-fill-available',
}

const Country = (props: CountryProps) => {
  return (
    <div style={props.isMobile ? countryStyleMobile : countryStyle}>
      <h2
        style={
          props.isMobile
            ? {
                padding: '16px 0',
                marginBottom: '0',
                marginLeft: '0',
                fontSize: '20px',
              }
            : {
                padding: '32px 0',
                marginBottom: '0',
                marginLeft: '32px',
                fontSize: '28px',
              }
        }
      >
        {props.countryName}
      </h2>
      <div style={props.isMobile ? cityListStyleMobile : cityListStyle}>
        {props.cities?.map((city, index) => (
          <div key={index} style={props.isMobile ? cityStyleMobile : cityStyle}>
            {city.cityName}
          </div>
        ))}
      </div>
    </div>
  )
}

const Location = (props: LocationProps) => {
  return (
    <div style={props.isMobile ? locationStyleMobile : locationStyle}>
      <h2
        style={
          props.isMobile
            ? { marginLeft: 0, marginBottom: '0', fontSize: '24px' }
            : { marginLeft: '20%', marginBottom: '0', fontSize: '32px' }
        }
      >
        {props.title ?? 'Our locations'}
      </h2>
      <div
        style={
          props.isMobile
            ? countriesContainerStyleMobile
            : countriesContainerStyle
        }
      >
        {props.country?.map((country, index) => (
          <Country
            key={index}
            {...(country as CountryProps)}
            isMobile={props.isMobile}
          />
        ))}
      </div>
    </div>
  )
}

export default Location

export const query = graphql`
  fragment Location on DatoCmsLocation {
    id
    title
    internal {
      type
    }
    country {
      cities {
        cityName
      }
      countryName
    }
  }
`
