import * as React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Page from '../components/page'
import { PageQueryQuery } from '../../schemas/graphqlTypings'

export interface PageProps {
  data: PageQueryQuery
  pageContext: {
    locale: string
    defaultLocale: string
    locales: Array<string>
    slug: string
  }
}

export const LocaleContext = React.createContext({
  lang: 'en',
  defaultLang: 'en',
  langs: ['en'],
})

export const NavContext = React.createContext({
  slug: '/',
})

const PageTemplate = (props: PageProps) => {
  React.useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.lang = props.pageContext.locale;
      klaro.render(klaro.defaultConfig)
    }
  });
  return (
    <NavContext.Provider value={{ slug: props.pageContext.slug }}>
      <LocaleContext.Provider
        value={{
          lang: props.pageContext.locale,
          defaultLang: props.pageContext.defaultLocale,
          langs: props.pageContext.locales,
        }}
      >
        <HelmetDatoCms
          seo={props.data.page?.seoMetaTags}
          favicon={props.data.datoCmsSite?.faviconMetaTags}
        />
        <Page {...props} />
      </LocaleContext.Provider>
    </NavContext.Provider>
  )
}

export default PageTemplate

export const query = graphql`
  query PageQuery($slug: String, $locale: String) {
    datoCmsHeader(locale: { eq: $locale }) {
      ...Header
    }
    datoCmsFooter(locale: { eq: $locale }) {
      ...Footer
    }
    datoCmsSite(locale: { eq: $locale }) {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...Page
    }
  }
`
