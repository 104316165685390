import { Link } from 'gatsby'
import * as React from 'react'
import { LocaleContext } from '../templates/Page'

const logoStyleCommon: React.CSSProperties = {
  height: '50px',
  display: 'flex',
}
const logoStyle: React.CSSProperties = {
  ...logoStyleCommon,
  marginLeft: '4em',
}
const logoStyleMobile: React.CSSProperties = {
  ...logoStyleCommon,
  marginLeft: '1em',
}

type HeaderLogoProps = {
  isMobile: boolean
  logoUrl: string
  logoAlt: string
}
export default function HeaderLogo({
  isMobile,
  logoUrl,
  logoAlt,
}: HeaderLogoProps) {
  const { lang, defaultLang } = React.useContext(LocaleContext)
  return (
    <Link to={lang === defaultLang ? '/' : `/${lang}`}>
      <img
        style={isMobile ? logoStyleMobile : logoStyle}
        src={logoUrl}
        alt={logoAlt}
      />
    </Link>
  )
}
