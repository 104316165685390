import * as React from 'react'
import { isCode } from 'datocms-structured-text-utils'
import {
  renderNodeRule,
  StructuredText,
  StructuredTextGraphQlResponseRecord,
  StructuredTextPropTypes,
} from 'react-datocms'

const WaveStyle: React.CSSProperties = {
  height: '3em',
}
export interface StructuredTextProps
  extends StructuredTextPropTypes<StructuredTextGraphQlResponseRecord> {
  renderWave?: boolean
  darkWave?: boolean
}

const StructuredTextBlock = (props: StructuredTextProps) =>
  props.renderWave ? (
    <StructuredText
      {...props}
      customNodeRules={[
        renderNodeRule(isCode, ({ key }) => {
          return (
            <img
              key={key}
              src={`/${props.darkWave ? 'wave-dark.svg' : 'wave.svg'}`}
              alt=""
              style={WaveStyle}
            />
          )
        }),
      ]}
    />
  ) : (
    <StructuredText {...props} />
  )

export default StructuredTextBlock
