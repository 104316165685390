import { Link } from 'gatsby'
import * as React from 'react'
import { NavigationFragment } from '../../schemas/graphqlTypings'
import '../styles/navLink.scss'
import { LocaleContext } from '../templates/Page'

interface NavLinkProps extends NavigationFragment {
  isButton?: boolean
  style?: React.CSSProperties
  linkTitle?: string
}

export const navLinkStyle = (
  isButton: boolean,
  propStyle?: React.CSSProperties
): React.CSSProperties => {
  let style: React.CSSProperties = {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: 'var(--colour-white)',
    fontWeight: 'bold',
    padding: '0.25em',
  }

  if (isButton) {
    style = {
      ...style,
      border: '2px solid var(--colour-blue-dark)',
      borderRadius: '0.5em',
      color: 'var(--colour-blue-dark)',
    }
  }
  return { ...style, ...propStyle }
}

const NavLink = (props: NavLinkProps) => {
  const locale = React.useContext(LocaleContext)
  return props.url ? (
    <a
      className={props.isButton ? 'nav-link-button' : 'nav-link-text'}
      href={props.url}
      style={navLinkStyle(props.isButton ?? false, props.style)}
    >
      {props.title}
    </a>
  ) : (
    <Link
      className={props.isButton ? 'nav-link-button' : 'nav-link-text'}
      to={
        locale.lang === locale.defaultLang
          ? `/${props.page?.slug}` ?? '/'
          : `/${locale.lang}/${props.page?.slug}` ?? '/'
      }
      style={navLinkStyle(props.isButton ?? false, props.style)}
    >
      {props.linkTitle ?? props.title}
    </Link>
  )
}

export default NavLink
