import * as React from 'react'
import { FooterFragment, HeaderFragment } from '../../schemas/graphqlTypings'
import { Footer } from './footer'
import Header from './header'
import '../styles/global.scss'

const contentWrapperSyle: React.CSSProperties = {
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

export type LayoutProps = {
  header: HeaderFragment
  footer: FooterFragment
  children: Array<JSX.Element>
  isMobile: boolean
}

const Layout = (props: LayoutProps) => {
  return (
    <div style={contentWrapperSyle}>
      <div>
        <Header {...props.header} isMobile={props.isMobile} />
        {props.children}
      </div>
      <div>
        <Footer {...props.footer} isMobile={props.isMobile} />
      </div>
    </div>
  )
}

export default Layout
