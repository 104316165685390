import { graphql } from 'gatsby'
import * as React from 'react'
import { DownloadAppMenuFragment } from '../../schemas/graphqlTypings'
import '../styles/downloadAppMenu.scss'

interface DownloadAppMenuProps extends DownloadAppMenuFragment {
  isFooter?: boolean
}

const DownloadAppMenu = (props: DownloadAppMenuProps) => {
  return (
    <div
      className={
        props.isFooter ? 'download-app-menu footer' : 'download-app-menu other'
      }
    >
      <h2>{props.title}</h2>
      {props.downloadAppButtons?.map((button, index) => (
        <a key={index} href={button?.link ?? ''}>
          <img src={button?.image?.url ?? ''} />
        </a>
      ))}
    </div>
  )
}

export default DownloadAppMenu

export const query = graphql`
  fragment DownloadAppMenu on DatoCmsDownloadAppMenu {
    title
    downloadAppButtons {
      image {
        url
      }
      link
    }
  }
`
