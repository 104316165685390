import { graphql } from 'gatsby'
import * as React from 'react'
import { TripleSplitFragment } from '../../schemas/graphqlTypings'
import SmallInfoCard from './smallInfoCard'
import '../styles/tripleSplit.scss'

const TripleSplit = (props: TripleSplitFragment) => {
  console.log(props)
  return (
    <div style={{ padding: '1rem 1rem 3rem 1rem' }}>
      <div
        className="triple-spit"
        style={{
          backgroundColor: props.backgrounColor?.hex ?? 'var(--colour-gold)',
        }}
      >
        {props.title && <h1>{props.title}</h1>}
        <div className="cards">
          {props.infoCards?.map((card, index) => (
            <SmallInfoCard key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TripleSplit

export const query = graphql`
  fragment TripleSplit on DatoCmsTripleSplit {
    internal {
      type
    }
    title
    backgrounColor {
      hex
    }
    infoCards {
      ... on DatoCmsSmallInfoCard {
        ...SmallInfoCard
      }
    }
  }
`
