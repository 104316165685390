import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { SmallInfoCardFragment } from '../../schemas/graphqlTypings'
import { LocaleContext } from '../templates/Page'

const SmallInfoCard = (props: SmallInfoCardFragment) => {
  const locale = React.useContext(LocaleContext)
  return (
    <Link className="small-info-card" to={locale.lang === locale.defaultLang ? `/${props.link?.slug}` ?? '/' : `/${locale.lang}/${props.link?.slug}` ?? '/'}>
      <img src={props.image?.url ?? ''} alt={props.image?.alt ?? ''} />
      <h3>{props.title}</h3>
    </Link>
  )
}

export default SmallInfoCard

export const query = graphql`
  fragment SmallInfoCard on DatoCmsSmallInfoCard {
    title
    link {
      slug
    }
    image {
      alt
      url
    }
  }
`
