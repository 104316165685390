import { graphql } from 'gatsby'
import * as React from 'react'
import { SubpageContentFragment } from '../../schemas/graphqlTypings'
import StructuredTextBlock, { StructuredTextProps } from './structuredTextBlock'
import '../styles/text.scss'

export interface SubpageContentProps extends SubpageContentFragment {
  isMobile: boolean
}

const subpageContentStyleCommon: React.CSSProperties = {
  textAlign: 'left',
  minHeight: '300px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}

const subpageContentStyle: React.CSSProperties = {
  ...subpageContentStyleCommon,
  padding: '3em 5rem',
}

const subpageContentStyleMobile: React.CSSProperties = {
  ...subpageContentStyleCommon,
  padding: '3em',
  textAlign: 'center'
}

const galleryStyle: React.CSSProperties = {
  display: 'flex',
  padding: '2em',
  textAlign: 'center',
}

const collaboratorStyle: React.CSSProperties = {
  alignContent: 'center',
  width: '100%',
}

const imagesContainerStyle = (isMobile: boolean): React.CSSProperties => ({
  display: 'grid',
  gridTemplateColumns: isMobile
    ? 'repeat(1, 1fr)'
    : 'repeat(auto-fill, minmax(150px, 200px))',
  gridAutoRows: isMobile ? 'minmax(100px, 100px)' : 'minmax(100px, 1fr)',
  gap: isMobile ? '0.75em' : '0.5em',
})

const collaboratorImageStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  display: 'grid',
}

const SubpageContent = (props: SubpageContentProps) => {
  return (
    <div className="subpage-content" style={props.isMobile ? subpageContentStyleMobile : subpageContentStyle}>
      <div>
        {props.mainText && (
          <StructuredTextBlock
            {...({
              data: props.mainText,
              renderWave: false,
            } as StructuredTextProps)}
          />
        )}
      </div>
      <div style={galleryStyle}>
        {props.collaborators &&
          props.collaborators.length > 0 &&
          props.collaborators?.map((collaborator, indexC) => {
            if (!collaborator) return <></>
            return (
              <div style={collaboratorStyle} key={indexC}>
                <h3 style={{ padding: '1em 0' }}>{collaborator.title}</h3>
                <div style={imagesContainerStyle(props.isMobile)}>
                  {collaborator?.images?.map((img, index) => (
                    <img
                      key={index}
                      src={img?.url ?? ''}
                      alt={img?.alt ?? ''}
                      style={collaboratorImageStyle}
                    />
                  ))}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default SubpageContent

export const query = graphql`
  fragment SubpageContent on DatoCmsSubpageContent {
    internal {
      type
    }
    collaborators {
      title
      images {
        alt
        url
      }
    }
    mainText {
      value
      links
    }
  }
`
