import { graphql } from 'gatsby'
import * as React from 'react'
import { ButtonFragment } from '../../schemas/graphqlTypings'
import '../styles/button.scss'

export interface ButtonProps extends ButtonFragment {
  isMobile: boolean
}

const buttonStyle = (isMobile: boolean, color?: string): React.CSSProperties => ({
  backgroundColor: color ?? 'var(--colour-blue-dark)',
  color: 'var(--colour-white)',
  borderRadius: '0.5em',
  fontSize: isMobile ? '1.2em' : '1.5em',
  border: 'none',
  fontWeight: 'bold',
  padding: '0.5em',
  textDecoration: 'none',
})

const linkStyle = (color?: string): React.CSSProperties => ({
  backgroundColor: color ?? 'var(--colour-blue-dark)',
  fontSize: '1.5em',
  fontWeight: 'bold',
  textDecoration: 'none',
})

export const Button = (props: ButtonProps) => {
  return props.cta ? (
    <a href={props.link ?? ''}>
      <button style={buttonStyle(props.isMobile, props.color?.hex)} className="button">
        {props.text ?? ''}
      </button>
    </a>
  ) : (
    <a href={props.link ?? ''}>
      <button style={linkStyle(props.color?.hex)} className="button">
        {props.text ?? ''}
      </button>
    </a>
  )
}

export const query = graphql`
  fragment Button on DatoCmsButton {
    color {
      alpha
      blue
      green
      hex
      red
    }
    link
    cta
    text
  }
`
