import React, { ReactChild, useEffect, useRef } from 'react'
import '../styles/dropdown.scss'

type DropDownProps = {
  children?: ReactChild[]
  opener: React.RefObject<HTMLElement>
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Dropdown({
  isOpen,
  setOpen,
  children,
  opener,
}: DropDownProps) {
  const ref = useRef<HTMLUListElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node | null) &&
        event.target !== opener.current
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [setOpen])
  return (
    <ul
      ref={ref}
      className="dropdown"
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      {isOpen &&
        children &&
        children.map((child, index) => {
          return (
            <li className="dropdown-item" key={index}>
              {child}
            </li>
          )
        })}
    </ul>
  )
}
