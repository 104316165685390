import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import '../styles/global.scss'
import { useMediaQuery } from '../util/useMediaQuery'
import {
  TripleSplitFragment,
} from '../../schemas/graphqlTypings'
import NotFoundPage from '../pages/404'
import Hero, { HeroProps } from '../components/hero'
import Location, { LocationProps } from '../components/location'
import MediumInfoCard, {
  MediumInfoCardProps,
} from '../components/mediumInfoCard'
import TripleSplit from '../components/tripleSplit'
import TextBlock, { TextBlockProps } from '../components/textBlock'
import SubpageContent, { SubpageContentProps } from './subpageContent'
import { PageProps } from '../templates/Page'

const Page = ({ data }: PageProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  if (
    !(
      data.datoCmsHeader &&
      data.datoCmsFooter &&
      data.page &&
      data.page.content
    )
  ) {
    return <NotFoundPage />
  }
  return (
    <main>
      <Layout
        header={data.datoCmsHeader}
        footer={data.datoCmsFooter}
        isMobile={isMobile}
      >
        {data.page.content.map((block, index) => {
          switch (block?.internal.type) {
            case 'DatoCmsHero':
              return (
                <Hero {...({ ...block, isMobile } as HeroProps)} key={index} />
              )
            case 'DatoCmsLocation':
              return (
                <Location
                  {...({ ...block, isMobile } as LocationProps)}
                  key={index}
                />
              )
            case 'DatoCmsMediumInfoCard':
              return (
                <MediumInfoCard
                  {...({ ...block, isMobile } as MediumInfoCardProps)}
                  key={index}
                />
              )
            case 'DatoCmsTripleSplit':
              return (
                <TripleSplit {...(block as TripleSplitFragment)} key={index} />
              )
            case 'DatoCmsTextBlock':
              return (
                <TextBlock
                  {...({ ...block, isMobile } as TextBlockProps)}
                  key={index}
                />
              )
            case 'DatoCmsSubpageContent':
              return (
                <SubpageContent
                  {...({ ...block, isMobile } as SubpageContentProps)}
                  key={index}
                />
              )
          }
          return <></>
        })}
      </Layout>
    </main>
  )
}

export default Page

export const query = graphql`
  fragment Page on DatoCmsPage {
    title
    content {
      ... on DatoCmsHero {
        ...Hero
      }
      ... on DatoCmsLocation {
        ...Location
      }
      ... on DatoCmsMediumInfoCard {
        ...MediumInfoCard
      }
      ... on DatoCmsTextBlock {
        ...TextBlock
      }
      ... on DatoCmsTripleSplit {
        ...TripleSplit
      }
      ... on DatoCmsSubpageContent {
        ...SubpageContent
      }
    }
    seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
    }
  }
`
