import { graphql } from 'gatsby'
import * as React from 'react'
import { FooterFragment } from '../../schemas/graphqlTypings'
import NavLink from './navLink'
import '../styles/footer.scss'
import DownloadAppMenu from './downloadAppMenu'

export type FooterProps = FooterFragment & {
  isMobile: boolean
}

const footerStyleCommon: React.CSSProperties = {
  backgroundColor: 'var(--colour-blue-dark)',
  color: 'var(--colour-white)',
  width: '100%',
  display: 'flex',
  padding: '2rem 0 0 0',
}

const footerStyleMobile: React.CSSProperties = {
  ...footerStyleCommon,
  flexDirection: 'column',
}

const FooterStyle: React.CSSProperties = {
  ...footerStyleCommon,
  flexDirection: 'row',
}

const navLinkPropStyles = (isMobile: boolean): React.CSSProperties => ({
  fontWeight: 'normal',
  fontSize: isMobile ? '1rem' : '1rem',
})

const FooterYearStyle: React.CSSProperties = {
  color: 'var(--colour-white)',
  margin: '0',
  padding: '1rem',
  fontWeight: 'var(--font-light)',
  fontStyle: 'italic',
  fontSize: '1rem',
  textAlign: 'center',
}

export const Footer = (props: FooterProps) => {
  return (
    <div
      style={{
        backgroundColor: ' var(--colour-blue-dark)',
        minHeight: 'var(--footer-min-height)',
      }}
    >
      <div style={props.isMobile ? footerStyleMobile : FooterStyle}>
        <div className="third center-items">
          <img className={'footer-icon'} src={props.logo?.url ?? ''} />
          <div>
            {props.downloadAppMenu && props.downloadAppMenu.length > 0 && (
              <DownloadAppMenu {...props.downloadAppMenu[0]} isFooter={true} />
            )}
            {!props.isMobile && (
              <p style={FooterYearStyle}>© Bout {new Date().getFullYear()}</p>
            )}
          </div>
        </div>
        <div className="third center">
          <div className="footer-nav-links">
            {props.linksCenterTop?.map((page, index) => (
              <NavLink
                key={index}
                {...page}
                isButton={false}
                style={navLinkPropStyles(props.isMobile)}
              />
            ))}
          </div>
          <div
            className="footer-nav-links"
            style={
              props.isMobile ? { marginTop: '2em' } : { paddingBottom: '2em' }
            }
          >
            {props.linksCenterBottom?.map((page, index) => (
              <NavLink
                key={index}
                {...page}
                isButton={false}
                style={navLinkPropStyles(props.isMobile)}
              />
            ))}
          </div>
        </div>
        <div className="third right">
          <div className="footer-nav-links">
            {props.linksRight?.map((page, index) => (
              <NavLink
                key={index}
                {...page}
                isButton={false}
                style={navLinkPropStyles(props.isMobile)}
              />
            ))}
          </div>
          <div className="social-icons">
            {props.socialMediaMenu &&
              props.socialMediaMenu.length > 0 &&
              props.socialMediaMenu[0]!.socialMediaButton?.map(
                (button, index) => (
                  <a key={index} href={button?.link ?? ''}>
                    <img src={button?.icon?.url ?? ''} />
                  </a>
                )
              )}
          </div>
        </div>
      </div>
      {props.isMobile && (
        <p style={FooterYearStyle}>© Bout {new Date().getFullYear()}</p>
      )}
    </div>
  )
}

export const query = graphql`
  fragment SocialMediaMenu on DatoCmsSocialMediaMenu {
    internal {
      type
    }
    socialMediaButton {
      link
      backgroundColor {
        red
        hex
        green
        blue
        alpha
      }
      icon {
        url
      }
    }
  }
  fragment Footer on DatoCmsFooter {
    logo {
      url
    }
    linksCenterTop {
      url
      title
      page {
        slug
      }
    }
    linksCenterBottom {
      url
      title
      page {
        slug
      }
    }
    linksRight {
      url
      title
      page {
        slug
      }
    }
    socialMediaMenu {
      ... on DatoCmsSocialMediaMenu {
        ...SocialMediaMenu
      }
    }
    downloadAppMenu {
      ... on DatoCmsDownloadAppMenu {
        ...DownloadAppMenu
      }
    }
  }
`
