import { graphql, Link } from 'gatsby'
import * as React from 'react'
import NavLink from './navLink'
import { HeaderFragment } from '../../schemas/graphqlTypings'
import HeaderLogo from './headerLogo'
import { useContext } from 'react'
import { LocaleContext, NavContext } from '../templates/Page'
import Dropdown from './dropdown'
import { useState } from 'react'
import '../styles/header.scss'

type HeaderProps = HeaderFragment & {
  isMobile: boolean
}

const headerStyle: React.CSSProperties = {
  backgroundColor: 'white',
  height: 'var(--header-height)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const navLinkButtonsStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  height: '100%',
}

const navLinkButtonsStyleMobile: React.CSSProperties = {
  ...navLinkButtonsStyle,
  fontSize: '0.8rem',
}

const rightContainerStyle = (
  headerOpen: boolean,
  isMobile: boolean
): React.CSSProperties => ({
  display: headerOpen ? 'block' : 'flex',
  flexDirection: 'row',
  height: '100%',
  marginRight: isMobile ? '1em' : '2em',
})

export default function Header(props: HeaderProps) {
  const openerRef = React.useRef<HTMLSpanElement>(null)
  const locale = useContext(LocaleContext)
  const { slug } = useContext(NavContext)
  const [headerOpen, setHeaderOpen] = useState(false)

  return (
    <div style={headerStyle}>
      <HeaderLogo
        isMobile={props.isMobile}
        logoUrl={props.logo?.url ?? ''}
        logoAlt={props.logo?.alt ?? 'Bout'}
      />
      <div style={rightContainerStyle(headerOpen, props.isMobile)}>
        <div
          style={
            props.isMobile ? navLinkButtonsStyleMobile : navLinkButtonsStyle
          }
        >
          {props.pages?.map((page, index) => {
            return (
              <NavLink
                {...page}
                isButton={true}
                key={index}
                style={{ marginLeft: '0.5em' }}
              />
            )
          })}
          <div className="lang-picker">
            <span ref={openerRef} onClick={() => setHeaderOpen(!headerOpen)}>
              {(() => {
                const navLang = props.locales?.find(
                  (loc) => loc?.langCode === locale.lang
                )
                return navLang ? (
                  <img
                    src={navLang?.flagIcon?.url ?? ''}
                    alt={navLang?.flagIcon?.alt ?? 'EN'}
                    style={{ width: '1em', borderRadius: '0.2em' }}
                  />
                ) : (
                  '🇬🇧'
                )
              })()}
            </span>
          </div>
        </div>
        <div>
          <Dropdown
            isOpen={headerOpen}
            setOpen={setHeaderOpen}
            opener={openerRef}
          >
            {locale.langs
              .filter((lang) => lang !== locale.lang)
              .map((lang, index) => {
                const locInfo = props.locales?.find(
                  (loc) => loc?.langCode === lang
                )
                if (!locInfo) {
                  return <></>
                }
                return (
                  <Link
                    to={
                      lang === locale.defaultLang
                        ? `/${slug}` ?? '/'
                        : `/${lang}/${slug}` ?? '/'
                    }
                    key={index}
                  >
                    <img
                      src={locInfo?.flagIcon?.url ?? ''}
                      alt={locInfo?.flagIcon?.alt ?? 'EN'}
                      style={{ width: '1em', borderRadius: '0.2em' }}
                    />{' '}
                    {locInfo.languageName}
                  </Link>
                )
              })}
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment Header on DatoCmsHeader {
    pages {
      title
      url
      page {
        slug
      }
    }
    logo {
      url
      alt
    }
    locales {
      langCode
      languageName
      flagIcon {
        url
        alt
      }
    }
  }
`
