import { graphql } from 'gatsby'
import * as React from 'react'
import { MediumInfoCardFragment } from '../../schemas/graphqlTypings'
import '../styles/infoCard.scss'
import NavLink from './navLink'
import DownloadAppMenu from './downloadAppMenu'
import StructuredTextBlock, { StructuredTextProps } from './structuredTextBlock'

export type MediumInfoCardProps = MediumInfoCardFragment & {
  isMobile: boolean
}

const infoCardStyleCommon = (blue: boolean): React.CSSProperties => ({
  backgroundColor: blue ? 'var(--colour-blue-light)' : 'var(--colour-white)',
  display: 'flex',
})

const infoCardStyle = (blue: boolean): React.CSSProperties => ({
  ...infoCardStyleCommon(blue),
  flexDirection: 'row',
  alignItems: 'center',
})

const infoCardStyleMobile = (blue: boolean): React.CSSProperties => ({
  ...infoCardStyleCommon(blue),
  flexDirection: 'column-reverse',
  padding: '1rem',
})

const containerLeftStyle: React.CSSProperties = {
  padding: '2em 2em',
  width: '55%',
  textAlign: 'center',
  minHeight: '300px',
  fontWeight: 'var(--font-light)',
}

const containerLeftStyleMobile: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
}

const containerRightStyle: React.CSSProperties = {
  padding: '1em 2em',
  width: '45%',
}

const containerRightStyleMobile: React.CSSProperties = {
  width: '100%',
  padding: '1rem 0',
}

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}

const MediumInfoCard = (props: MediumInfoCardProps) => {
  return (
    <div style={{ padding: '1rem' }}>
      <div
        className="info-card"
        style={
          props.isMobile
            ? infoCardStyleMobile(props.blue!)
            : infoCardStyle(props.blue!)
        }
      >
        <div
          style={props.isMobile ? containerLeftStyleMobile : containerLeftStyle}
        >
          <StructuredTextBlock
            {...({
              data: props.textContent,
              renderWave: true,
              darkWave: props.blue ?? false,
            } as StructuredTextProps)}
          />
          {props.linkToInternalSite && (
            <NavLink
              {...props.linkToInternalSite}
              style={{
                color: 'var(--colour-blue-dark)',
                textDecoration: 'underline',
              }}
              linkTitle={props.internalLinkTitle ?? undefined}
            />
          )}
          {props.downloadAppMenu && props.downloadAppMenu.length > 0 && (
            <DownloadAppMenu {...props.downloadAppMenu[0]} isFooter={false} />
          )}
        </div>
        <div
          style={
            props.isMobile ? containerRightStyleMobile : containerRightStyle
          }
        >
          <img
            style={imageStyle}
            src={props.image?.url ?? ''}
            alt={props.image?.alt ?? ''}
          />
        </div>
      </div>
    </div>
  )
}

export default MediumInfoCard

export const query = graphql`
  fragment MediumInfoCard on DatoCmsMediumInfoCard {
    internal {
      type
    }
    downloadAppMenu {
      title
      downloadAppButtons {
        link
        image {
          url
          alt
        }
      }
    }
    textContent {
      links
      value
    }
    image {
      url
      alt
    }
    blue
    internalLinkTitle
    linkToInternalSite {
      id
      url
      page {
        slug
      }
      title
    }
  }
`
